import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBasket, IBasketItemWithProduct, IBasketResponse } from './basket.service';
import { ProductInventoryStatus } from './icebreak-webshop.interface';
import { addLanguageKeyInUrl } from './menu.service';
interface IOrderGroupResponse {
  status_code: number;
  description: string;
  items?: IOrderResponse[];
}

interface IOrderResponse extends IBasketResponse {
  order_status: number;
  orderNumber: string;
  order_status_description: string;
  order_date: string;
  download_path?: string;
  invoice_date?: string;
  invoice_number?: string;
}

export interface IOrder extends IBasket {
  finalId: string; // id and orderNumber both are optional (can be empty) so this field so we can have a id
  status: number;
  orderNumber: string;
  statusDescription: string;
  downloadPath?: string;
  orderDate: string;
  invoiceDate?: string;
  invoiceNumber?: string;
}

const orderResponseToOrder = (order: IOrderResponse): IOrder => {
  return {
    finalId: order.order_id ? `${order.order_id}` : order.orderNumber,
    id: `${order.order_id}`,
    status: order.order_status,
    orderNumber: order.orderNumber,
    statusDescription: order.order_status_description,
    orderDate: order.order_date,
    totalProductGross: order.total_net_amount + order.total_discount_amount,
    totalProductNet: order.total_net_amount,
    totalDiscount: order.total_discount_amount,
    totalFreight: order.total_freight_amount,
    totalRoadTax: order.order_total_roadTax,
    totalVATBasis: order.total_vat_basis,
    totalVAT: order.total_vat,
    totalTax: order.total_tax,
    totalPayableAmount: order.total_total_order,
    deliverAtWarehouse: !!order.properties?.deliverAtWarehouse,
    deliverAtMyCompanyAddress: !!order.properties?.deliverAtMyCompanyAddress,
    requestForOffer: !!order.properties?.offer,
    deliveryAddress:
      order.delivery_address_name || order.delivery_address_1 || order.delivery_zipcode || order.delivery_city
        ? {
            name: order.delivery_address_name,
            address1: order.delivery_address_1,
            address2: order.delivery_address_2,
            postalCode: order.delivery_zipcode,
            city: order.delivery_city,
          }
        : undefined,
    billingAddress:
      order.billing_address_name || order.billing_address_1 || order.billing_zipcode || order.billing_city
        ? {
            name: order.billing_address_name,
            address1: order.billing_address_1,
            address2: order.billing_address_2,
            postalCode: order.billing_zipcode,
            city: order.billing_city,
          }
        : undefined,
    additionalInformation: order.properties?.additionalInformation,
    items: [],
    downloadPath: order.download_path && `${environment.apiDomain}${order.download_path}`,
    invoiceNumber: order.invoice_number,
    invoiceDate: order.invoice_date,
  };
};

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private readonly http = inject(HttpClient);
  private readonly translateService = inject(TranslateService);

  public getMyOrders(): Observable<{
    statuss: {
      label: string;
      value: number;
    }[];
    orders: IOrder[];
  }> {
    return this.http.get<IOrderGroupResponse[]>('@api/cms/ord/ordersByCustomer').pipe(
      // Filter out groups without items and Flat orders into one array
      map(ordersGroups => {
        return ordersGroups.reduce(
          (acc, orderGroup) => {
            return {
              statuss: [
                ...acc.statuss,
                {
                  label: orderGroup.description,
                  value: orderGroup.status_code,
                },
              ],
              orders: [...acc.orders, ...(orderGroup.items || [])],
            };
          },
          {
            statuss: [],
            orders: [],
          } as {
            statuss: {
              label: string;
              value: number;
            }[];
            orders: IOrderResponse[];
          }
        );
      }),
      // Map to internal model
      map(data => {
        return {
          ...data,
          orders: data.orders.map(orderResponseToOrder),
        };
      })
    );
  }

  public getOrderLines(orderId: string): Observable<IBasketItemWithProduct[]> {
    return this.http
      .get<
        {
          order_line_id: number;
          quantity: number;
          price: number;
          product: {
            product_id: number;
            seourl: string;
            image: string;
            title: string;
            subLabel: string; // TODO: this is missing in response
            productNumber: string;
            uom: string; // TODO: MIR
            productOrderType: string;
          };
          inventory: {
            inventoryStatus: ProductInventoryStatus;
          };
          minimumOrderQuantityText: string;
          unitQuantity: number;
          customerPrice: {
            unitNormalPrice: number;
            unitYourPrice: number;
            listPrice: number;
            linePrice: number;
            salesPrice: number;
            lineTax: number;
          };
        }[]
      >(`@api/cms/ord/order/${orderId}/lines`)
      .pipe(
        map(items =>
          items.map(i => {
            return {
              id: `${i.order_line_id}`,
              quantity: i.quantity,
              price: i.customerPrice.linePrice,
              unitYourPrice: i.customerPrice.unitYourPrice,
              unitNormalPrice: i.customerPrice.unitNormalPrice,
              unitQuantity: i.unitQuantity,
              product: {
                id: `${i.product.product_id}`,
                seourl: i.product.seourl,
                url: addLanguageKeyInUrl(
                  i.product.seourl,
                  this.translateService.currentLang,
                  this.translateService.getLangs()
                ),
                unitOfMeasurement: i.product.uom,
                title: i.product.title,
                subTitle: i.product.subLabel,
                inventoryStatus: i.inventory.inventoryStatus,
                productNumber: i.product.productNumber,
                price: i.customerPrice.salesPrice,
                pricePerUnitOfMeasurement: i.customerPrice?.unitYourPrice,
                listPrice: i.customerPrice.listPrice,
                minimumOrderQuantityText: i.minimumOrderQuantityText,
                orderUnitOfMeasurement: i.product.productOrderType,
                lineTax: i.customerPrice.lineTax || 0,
                assets: [
                  {
                    type: 'image',
                    link: i.product.image,
                    alt: i.product.title,
                  },
                ],
              },
            };
          })
        )
      );
  }
}
